.navBarLogo {
    width: 56px;
    height: 56px;
    margin: 2px 0 0 24px;
    cursor: pointer;
}

.navBarTitle {
    text-align: left;
    width: 240px;
    color: white;
    font-size: 38px;
    margin: 2px 0 0 8px;
    cursor: pointer;
}

.navBarSpacer {
    height: 100%;
    width: 2px;
    background-color: white;
    min-width: 2px;
}

.navBarTab {
    color: white;
    height: 100%;
    line-height: 56px;
    cursor: pointer;
    margin: 0 24px;
    text-align: center;
    float: right;
    min-width: 86px;
    font-weight: bold;
}

.navBarContainer {
    position: relative;
    height: 56px;
    background-color: #EC3924;
    display: flex;
    left: 0;
}

.navBarTabContainer {
    float: right;
    position: relative;
    justify-items: right;
    display: flex;
    height: 50%;
    margin: auto;
    right: 0;
}

@media only screen and (max-width: 800px) {
    .navBarLogo {
        margin: 2px 0 0 16px;
    }
    .navBarTab {
        margin: 0 16px;
    }
    .navBarTitle {
        width: 100px;
        font-size: 20px;
        margin: 4px 0 0 8px;
    }
}
