.QuickStartPage {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    color: white;
  }
  
  .QuickStartPageContent {
    text-align: left;
    position: relative;
    max-height: calc(100% - 16px);
    max-width: 720px;
    background-color: #2e2e2e88;
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
    overflow: scroll;
  }

.QuickStartPage-logo {
    height: 40vmin;
}
