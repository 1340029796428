.App {
  text-align: center;
}

.AppViewContainer {
  background-color: #000000;
  position: absolute;
  top: 56px;
  bottom: 0px;
  width: 100%;
}

a {
  color: #FEAAA0;
}
